import React from 'react'
import './style.css'
import { Jumbotron } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default class LandingPage extends React.Component {
    render() {
        return(
            <div className='img-responsive'>
                <div className='fullscreen-bg'>
                <video autoPlay muted loop id="myVideo">
                    <source src="canvasVideo.mp4" type="video/mp4" />
                </video>
                </div>
                <Jumbotron style={{textAlign:'center', position: 'absolute', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.40)', borderRadius: '0'}} >
                    <img alt='grackle logo' src='grackle.png' width='160px' height='160px'/>
                    <h1>Welcome, to Grackle Canvas</h1>
                    <p>We turn your photos into a canvas fit for any room.</p>
                    <p>
                        <Link style={{ borderRadius: '0'}} to='/upload' className='btn btn-outline-dark'>Get Started</Link>
                    </p>
                    <div className='socialMedia'>
                        <a className='smLink' href='#!'><i className="fab fa-instagram"></i></a>
                        <a className='smLink' href='#!'><i className="fab fa-facebook-f"></i></a>
                    </div>
                </Jumbotron>
            </div>
        )
    }
}