import React from 'react'
import { ProgressBar } from 'react-bootstrap'
import { Button, Form, Row, Col } from 'react-bootstrap'
import CropImage from '../../components/CropImage'
import StandardSize from '../StandardSize'
import FileUploader from 'react-firebase-file-uploader'
import FadeIn from 'react-fade-in'
import firebase from 'firebase'

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DATABASEURL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
}

firebase.initializeApp(config)

export default class ImageUpload extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            image: '',
            imageURL: '',
            progress: 0,
            gettingCustom: false,
            gettingStandard: false
        }
    }

    handleUploadStart = (e) => {
        const reader = new FileReader();
        reader.addEventListener('load', () =>
            this.setState({ image: reader.result })
        );
        reader.readAsDataURL(e);
        this.setState({
            image: '',
            imageURL: '',
            progress: 0
        })
    }

    handleProgress = progress => this.setState({ progress })

    handleUploadSuccess = filename => {
        this.setState({
            progress: 100
        })
        firebase.storage().ref('temp_images').child(filename).getDownloadURL()
        .then(url => this.setState({ imageURL: url }))
    }
    
    handleCustomApproval = () => this.setState({ gettingCustom: true })
    
    handleStandardSize = () => this.setState({ gettingStandard: true })
    
    render() {
        return(
            <div>
                <div style={{display: this.state.gettingCustom || this.state.gettingStandard ? 'none' : 'show'}}>
                <FadeIn delay={400}>
                <h1 style={{margin: '40px 0 0 0'}} className='text-center' >Upload Your Image</h1>
                <label 
                    className='btn btn-outline-dark btn-lg'
                    style={{borderRadius: '0', display: 'block', margin: '40px auto 40px auto', maxWidth: '450px', cursor: 'pointer'}}>
                    {this.state.image === '' ? `Select Your New Canvas Photo` : `Select A Different Photo`}
                    <FileUploader
                        hidden
                        accept='image/*'
                        name='image'
                        storageRef={firebase.storage().ref('temp_images')}
                        onUploadStart={(e) => (this.handleUploadStart(e))}
                        onUploadSuccess={this.handleUploadSuccess}
                        onProgress={this.handleProgress}
                    />
                </label>
                {this.state.imageURL === '' 
                ? <ProgressBar 
                    variant='dark' 
                    animated now={this.state.progress} 
                    style={{margin: '20px auto 10px auto', maxWidth: '450px'}}
                    />
                : <div style={{maxWidth: '700px', margin: '0 auto'}} >
                    <img 
                    alt='uploaded canvas' 
                    src={this.state.imageURL}
                    style={{ width:'100%'}} 
                    />
                </div>
                }
                </FadeIn>
                    <Form style={{maxWidth: '700px', margin: '0 auto'}}>
                        <Row>
                            <Col>
                                {this.state.progress === 100 ? 
                                <FadeIn>
                                <Button
                                    block 
                                    size='lg'
                                    variant='outline-dark' 
                                    onClick={this.handleCustomApproval}
                                    style={{maxWidth: '350px', margin: '40px auto 40px auto', borderRadius: '0'}}>
                                    Fully Customize Your Size
                                </Button>
                                </FadeIn> : null}
                            </Col>
                            <Col>
                            {this.state.progress === 100 ? 
                            <FadeIn>
                            <Button
                                    block 
                                    size='lg'
                                    variant='outline-dark' 
                                    onClick={this.handleStandardSize}
                                    style={{maxWidth: '350px', margin: '40px auto 40px auto', borderRadius: '0'}}>
                                    Choose a Standard Size
                                </Button>
                                </FadeIn> : null}
                            </Col>
                        </Row>
                    </Form>
                </div>
                {this.state.gettingCustom ? <CropImage 
                    imageFile={this.state.image}
                    imageURL={this.state.imageURL}
                /> : null}
                {this.state.gettingStandard ? <StandardSize 
                    imageFile={this.state.image}
                    imageURL={this.state.imageURL}
                /> : null}
            </div>
        )
    }
}