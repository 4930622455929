import React from 'react'

class OrderConfirmation extends React.Component {
    render() {
        return(
            <div style={{textAlign: 'center'}}>
                <h2>Thank You For Your Business</h2>
                <h5>You Will Recieve an Order Confirmation within 24 Hours.</h5>
            </div>
        )
    }
}

export default OrderConfirmation
