import React from 'react'
import Customizer from '../Customizer'

export default class FrameSizing extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            imageURL: props.URL,
            imageFile: props.imageFile,
            frameSpecs: props.cropSpecs,
            dimensionsCalc: {
                longSide: 0,
                longName: '',
                shortSide: 0,
                shortName: ''
            }
        }
    }

    componentDidMount() {
        this.handleShortSideVsLongSide()
    }

    handleShortSideVsLongSide = () => {
        this.state.frameSpecs.height > this.state.frameSpecs.width ? 
        (this.setState(prevState => ({ 
            dimensionsCalc: {
                // ...prevState.dimensionsCalc,
                longSide: this.state.frameSpecs.height,
                shortSide: this.state.frameSpecs.width,
                longName: 'Height',
                shortName: 'Width'
            }
        }))) : 
              this.setState(prevState => ({
                dimensionsCalc: {
                    ...prevState.dimensionsCalc,
                    longSide: this.state.frameSpecs.width,
                    shortSide: this.state.frameSpecs.height,
                    longName: 'Width',
                    shortName: 'Height'
                }
        }))
    }

    render() {
        console.log(this.state.dimensionsCalc)
        return(
            <div style={{margin: '0 auto'}}>
                <Customizer 
                    longSide={this.state.dimensionsCalc.longSide}
                    longName={this.state.dimensionsCalc.longName}
                    shortSide={this.state.dimensionsCalc.shortSide}
                    shortName={this.state.dimensionsCalc.shortName}
                    image={this.props.imageURL}
                    imageURL={this.state.imageURL}
                />
            </div>
        )
    }
}