import React from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

export default class Navagation extends React.Component {
    render() {
        return(
            <Navbar collapseOnSelect bg="light" expand="lg">
                <LinkContainer to='/'>
                    <Navbar.Brand>
                        <img 
                            src='./grackle.png' 
                            width="60"
                            height="60"
                            alt="React Bootstrap logo"
                        />
                        Grackle Canvas
                    </Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle style={{border: 'none', outline: 'none'}} aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav activeKey='/' className="ml-auto">
                        <LinkContainer to='/upload'>
                            <Nav.Link>Get Started</Nav.Link>
                        </LinkContainer>
                        <LinkContainer disabled to='/about'>
                            <Nav.Link>About Grackle Canvas</Nav.Link>
                        </LinkContainer>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}