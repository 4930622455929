import React from 'react'
import StripeCheckout from 'react-stripe-checkout'
import { Button } from 'react-bootstrap'
import axios from 'axios'
import { useHistory } from 'react-router'

function Purchase(props) {
    console.log(props.price)
    let [product] = React.useState({ 
        name: 'Canvas',
        price: props.price,
        description: `Dimensions ${props.size}, ImageURL ${props.URL}`
     })
     console.log(product)

     let history = useHistory()
     async function handleToken(token, addresses) {
        console.log(token)
        const response = await axios.post('https://mqh9b.sse.codesandbox.io/checkout', {
            token,
            product
        })
        const { status } = response.data
        if (status === 'success') {
            console.log('success')
            history.push('/orderconfirmation')
        } else {
            console.log('something went wrong')
        }
     }

     return(
        <StripeCheckout 
            stripeKey='pk_test_0hfF7jNXMkn5JTk2tqzvStNw00t1C5Lvks'
            token={handleToken}
            billingAddress
            shippingAddress
            amount={product.price * 100}
            name={product.name}
        >
            <Button style={{borderRadius: '0px', margin: '40px 0 40px 0'}} 
                block size='lg' 
                variant="outline-dark">
                Pay and Order Your Canvas | ${props.price}
            </Button>
        </StripeCheckout>
     ) 
}

export default Purchase