import React from 'react'
import ImageUpload from '../../components/UploadImage'
import { Modal } from 'react-bootstrap'

export default class MainSite extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showModal: false
        }
    }

    componentDidMount() {
        localStorage.returningUser ? 
            (this.setState({ showModal: false }))
             : 
            (this.newUser())
    }

    newUser = () => {
        this.setState({ showModal: true })
        localStorage.setItem('returningUser', true)
    }

    close = () => {
    this.setState({ showModal: false });
    }

    open = () => {
    this.setState({ showModal: true });
    }
    
    
    render() {
        return(
            <div className="container">
                {/* <Link onClick={this.open} >Instructions</Link> */}
                <div id='uploadimage' style={{margin: '0 auto' }}>
                    <ImageUpload />
                </div>

                <Modal show={this.state.showModal} onHide={this.close}>
                <Modal.Header closeButton>
                <Modal.Title className="text-center" >
                    Getting Started
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5 style={{margin: "40px 0 20px 0"}}>1. Upload Your Image.</h5>
                    <h5 style={{margin: "20px 0 20px 0"}}>2. Create Your Custom Size.</h5>
                    <h5 style={{margin: "20px 0 40px 0"}}>3. Order and Enjoy.</h5>
                </Modal.Body>
                </Modal>
            </div>
        )
    }
}