import React from 'react'
import ReactCrop from 'react-image-crop'
import { Button, DropdownButton, Dropdown } from 'react-bootstrap'
import Purchase from '../Purchase'
// import FileUploader from 'react-firebase-file-uploader'
import firebase from 'firebase'
import 'react-image-crop/dist/ReactCrop.css'
import './style.css'

export default class StandardSize extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            src: this.props.imageFile,
            crop: {
                unit: '%',
                width: 30,
                aspect: '',
              },
            myBlob: null,
            croppedimageFile: '',
            showImageCropper: true,
            showFramer: false,
            error: {},
            croppedImageURL: '',
            price: 0, 
            size: "Choose Canvas Size",
            sizeArray: ['12" by 16"', '14" by 14"', '16" by 20"', '16" by 24"', '16" by 32"', '20" by 30"', '24" by 36"', '16" by 48"', '30" by 40"'],
            priceArray: [25, 25, 30, 35, 35, 40, 45, 50, 65]
        }
        this.handleAspectRatio.bind(this)
    }

    onImageLoaded = image => {
        // console.log('onCropComplete', image)
        this.imageRef = image
    }

    onCropComplete = crop => {
        // console.log('onCropComplete', crop)
        this.makeClientCrop(crop)
    }
    
    onCropChange = crop => {
        this.setState({ crop })
    }

    async makeClientCrop(crop) {
        // problem: the scope of the blob file will not change the declared variable. 
        //          need to determine how to either send this file to state or upload from 
        //          this function.
        let myBlob
        if (this.imageRef && crop.width && crop.height) {
            const croppedimageFile = await this.getCroppedImg(
                this.imageRef,
                crop,
                "newFile.jpeg"
            )
            var xhr = new XMLHttpRequest();
            xhr.open('GET', croppedimageFile, true);
            xhr.responseType = 'blob'
            xhr.onload = function(e) {

            // eslint-disable-next-line eqeqeq
            // I want a generic response of anything in the 200 respose code
            // eslint-disable-next-line eqeqeq
            if (this.status == 200) {
                myBlob = this.response
                myBlob.lastModifiedDate = new Date()
                myBlob.name = "croppedfile.jpg"
            }
        };
        xhr.send();
        this.setState({ croppedimageFile })
        }
    }
    
    handleImageCropperHide = () => {
        this.uploadBlob()
        this.setState({ showImageCropper: false })
        this.setState({ showFramer: true })
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas")
        canvas.crossOrigin = "Anonymous"
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        canvas.width = crop.width
        canvas.height = crop.height
        const ctx = canvas.getContext("2d")
    
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        )
    
        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                //reject(new Error('Canvas is empty'))
                console.error("Canvas is empty")
                return
                }
                blob.name = fileName
                window.URL.revokeObjectURL(this.fileUrl)
                this.fileUrl = window.URL.createObjectURL(blob)
                resolve(this.fileUrl)
                this.setState({ myBlob: blob})
            }, "image/jpeg")
        })
    }

    uploadBlob = () => {
        let storageRef = firebase.storage().ref('temp_cropped_images')
        let blob = this.state.myBlob
        let thisRef = storageRef.child(blob.name)

        thisRef.put(blob).then(function(snapshot) {
            console.log('Uploaded the blob')
        })
    }

    handleAspectRatio = (e) => {
        let index = e.target.getAttribute('id')
        let price = this.state.priceArray[index]
        this.setState({ price })
        
        this.setState({ size: e.target.getAttribute('value')})
        switch(e.target.getAttribute('value')) {
            case '12" by 16"':
            case '30" by 40"':
                this.setState(  prevState => ({
                    crop: {
                        ...prevState.crop,
                        aspect: 4 / 3
                    }
                }))
                break
            case '14" by 14"':
                this.setState( prevState => ({
                    crop: {
                        ...prevState.crop,
                        aspect: 1 / 1
                    }
                }))
                break
            case '16" by 20"':
                this.setState( prevState => ({
                    crop: {
                        ...prevState.crop,
                        aspect: 5 / 4
                    }
                }))
                break
            case '16" by 24"':
            case '20" by 30"':
            case '24" by 36"':
                this.setState( prevState => ({
                    crop: {
                        ...prevState.crop, 
                        aspect: 3 / 2
                    }
                }))
                break
            case '16" by 32"':
                this.setState( prevState => ({
                    crop: {
                        ...prevState.crop,
                        aspect: 2 / 1
                    }
                }))
                break
            case '16" by 48"':
                this.setState( prevState => ({
                    crop: {
                        ...prevState.crop,
                        aspect: 3 / 1
                    }
                }))
                break
        default: 
            let error = {}
            error.size = 'Please Choose a Canvas Size.'
            this.setState({ error })
        }
    }

    render() {
        return(
            <div>
                <div>
                    { !this.state.showFramer
                    ?
                    <>
                    <div id="imageCropperWindow" style={{display: this.state.showImageCropper ? 'show' : 'none', margin: '0 auto', maxWidth: '700px'}}>
                    <br />
                    <h1 style={{textAlign: 'center'}}>Choose a Standard Size</h1>
                    <DropdownButton variant='outline-dark' id="dropdown-basic-button" title={this.state.size}>
                        {this.state.sizeArray.map((size, i) => {
                            return <Dropdown.Item id={i} onClick={(e) => this.handleAspectRatio(e)} value={size} key={i}>{size}</Dropdown.Item>
                        })}
                    </DropdownButton>
                    {this.state.src && (
                    <ReactCrop
                        style={{ border: 'solid black 3px' }}
                        src={this.state.src}
                        crop={this.state.crop}
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                    />
                    )}
                    </div>
                    <div style={{display: this.state.showImageCropper ? 'show' : 'none'}} >
                    {this.state.croppedimageFile && (
                        <div style={{maxWidth: '500px', margin: '0 auto'}} >
                            <img alt="Crop" style={{ width: "100%", border: 'solid black 3px'}} src={this.state.croppedimageFile} />
                        </div>
                    )}
                    </div>
                    <h1 style={{textAlign: 'center'}}>Price: ${this.state.price}</h1>
                    <Button
                        block
                        size='lg'
                        variant='outline-dark' 
                        onClick = {this.handleImageCropperHide}
                        style={{maxWidth: '350px', margin: '40px auto 40px auto', borderRadius: '0', display: this.state.showImageCropper ? 'show' : 'none'}}>
                        Finalize Photo
                    </Button>
                    </>
                    :
                    <div>
                        <div style={{maxWidth: '500px', margin: '0 auto'}} >
                            <br />
                            <img alt="Crop" style={{ width: "100%" }} src={this.state.croppedimageFile} />
                            <Purchase URL={this.props.imageURL} price={this.state.price} size={this.state.size} />
                        </div>
                    </div>
                    }
                </div>
            </div>
        )
    }
}