import React from 'react'
import ReactCrop from 'react-image-crop'
import { Button } from 'react-bootstrap'
import FrameSizing from '../FrameSizing'
import { Animate, AnimateGroup } from 'react-simple-animate'
import firebase from 'firebase'
import 'react-image-crop/dist/ReactCrop.css'

export default class CropImage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            src: this.props.imageFile,
            crop: {
                x: 10,
                y: 10,
                width: 200,
                height: 200,
              },
            myBlob: null,
            croppedimageFile: '',
            showImageCropper: true,
            showFramer: false,
            croppedImageURL: ''
        }
    }

    onImageLoaded = image => {
        // console.log('onCropComplete', image)
        this.imageRef = image
    }

    onCropComplete = crop => {
        // console.log('onCropComplete', crop)
        this.makeClientCrop(crop)
    }
    
    onCropChange = crop => {
        this.setState({ crop })
    }

    async makeClientCrop(crop) {
        // problem: the scope of the blob file will not change the declared variable. 
        //          need to determine how to either send this file to state or upload from 
        //          this function.
        let myBlob
        if (this.imageRef && crop.width && crop.height) {
            const croppedimageFile = await this.getCroppedImg(
                this.imageRef,
                crop,
                "newFile.jpeg"
            )
            var xhr = new XMLHttpRequest();
            xhr.open('GET', croppedimageFile, true);
            xhr.responseType = 'blob'
            xhr.onload = function(e) {

            // eslint-disable-next-line eqeqeq
            // I want a generic response of anything in the 200 respose code
            // eslint-disable-next-line eqeqeq
            if (this.status == 200) {
                myBlob = this.response
                myBlob.lastModifiedDate = new Date()
                myBlob.name = "croppedfile.jpg"
            }
        };
        xhr.send();
        this.setState({ croppedimageFile })
        }
    }
    
    handleImageCropperHide = () => {
        this.uploadBlob()
        this.setState({ showImageCropper: false })
        this.setState({ showFramer: true })
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas")
        canvas.crossOrigin = "Anonymous"
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        canvas.width = crop.width
        canvas.height = crop.height
        const ctx = canvas.getContext("2d")
    
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        )
    
        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                //reject(new Error('Canvas is empty'))
                console.error("Canvas is empty")
                return
                }
                blob.name = fileName
                window.URL.revokeObjectURL(this.fileUrl)
                this.fileUrl = window.URL.createObjectURL(blob)
                resolve(this.fileUrl)
                this.setState({ myBlob: blob})
            }, "image/jpeg")
        })
    }

    uploadBlob = () => {
        let storageRef = firebase.storage().ref('temp_cropped_images')
        let blob = this.state.myBlob
        let thisRef = storageRef.child(blob.name)

        thisRef.put(blob).then(function(snapshot) {
            console.log('Uploaded the blob')
        })
    }

    render() {
        return(
            <div>
                <div>
                    { !this.state.showFramer
                    ?
                    <>
                    <div id="imageCropperWindow" style={{display: this.state.showImageCropper ? 'show' : 'none', margin: '0 auto', maxWidth: '700px'}}>
                    <Animate play delay={0.25} start={{ opacity: 0 }} end={{ opacity: 1 }}>
                        <h1 style={{textAlign: 'center'}}>Crop Your Picture to Your Liking</h1>
                    </Animate>
                    {this.state.src && (
                    <Animate play delay={0.5} start={{ opacity: 0 }} end={{ opacity: 1 }}>
                    <ReactCrop
                        src={this.state.src}
                        crop={this.state.crop}
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                    />
                    </Animate>
                    )}
                    </div>
                    <Animate play delay={0.75} start={{ opacity: 0 }} end={{ opacity: 1 }}>
                    <div style={{display: this.state.showImageCropper ? 'show' : 'none'}} >
                    {this.state.croppedimageFile && (
                            <div style={{maxWidth: '500px', margin: '0 auto'}} >
                                <img alt="Crop" style={{ width: "100%" }} src={this.state.croppedimageFile} />
                            </div>
                    )}
                    </div>
                    </Animate>
                    <Animate play delay={1} start={{ opacity: 0 }} end={{ opacity: 1 }}>
                        <Button
                            block
                            size='lg'
                            variant='outline-dark' 
                            onClick = {this.handleImageCropperHide}
                            style={{maxWidth: '350px', margin: '40px auto 40px auto', borderRadius: '0', display: this.state.showImageCropper ? 'show' : 'none'}}>
                            Finalize Photo
                        </Button>
                    </Animate>
                    </>
                    :
                    <FrameSizing
                        imageURL={this.state.croppedimageFile}
                        imageFile={this.state.myBlob}
                        cropSpecs={this.state.crop}
                        URL={this.props.imageURL}
                    />
                    }
                </div>
            </div>
        )
    }
}