import React from 'react'
import Slider from 'rc-slider'
import FadeIn from 'react-fade-in'
import { Button } from 'react-bootstrap'
import Purchase from '../Purchase'
import 'rc-slider/assets/index.css'

export default class Customizer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: 5,
            width: 0,
            height: 0,
            price: 0,
            isConfirmed: false
        }
    }

    componentDidMount() {
        let value = 5
        let width = Math.floor(this.state.value * (this.props.longSide/this.props.shortSide))
        this.setState({
            value: value,
            width: width,
            height: value
        })
    }

    handleSliderChange = (value) => {
        let width = Math.floor(this.state.value * (this.props.longSide/this.props.shortSide))
        this.setState({ 
            value,
            width: width,
            height: value
         })
    }

    handleConfirm = () => {
        this.setState({ isConfirmed: true })
    }

    render() {
        console.log(this.state)
        return(
            <div>
                <FadeIn>
                <h1 style={{textAlign: 'center', margin: '40px 0 0 0'}} >Choose canvas size</h1>
                <div style={{ margin: '40px auto 40px auto', maxWidth: '420px'}}>
                    <FadeIn>
                    <div>                   
                        <img style={{width: '100%', height: 'auto', margin: '0 auto'}} alt='final render' src={this.props.image} />
                    </div>
                    <br /><br />
                    <div style={{ textAlign: 'center'}}>
                        <h2><span style={{color: '#333333'}} >{this.props.shortName}{' '}</span><b>{this.state.value}"{'  '}</b><span style={{color: '#333333'}} >{this.props.longName}{' '}</span><b>{Math.floor(this.state.value * (this.props.longSide/this.props.shortSide))}"</b></h2>
                    </div>
                    {!this.state.isConfirmed ?
                        <div>
                            <Slider 
                                min={5} 
                                max={34} 
                                defaultValue={5} 
                                value={this.state.value} 
                                onChange={this.handleSliderChange}
                                style={{ margin: '40px 0 0 0'}}
                            />
                            <h1 style={{ textAlign: 'center' }}>Price: ${0.5 * Math.floor(this.state.value * (this.props.longSide/this.props.shortSide)) * this.state.height}</h1>
                            <Button onClick={this.handleConfirm} block variant='outline-dark'>Confirm Canvas Size</Button>
                        </div>
                        :
                        <Purchase URL={this.props.imageURL} price={0.5 * Math.floor(this.state.value * (this.props.longSide/this.props.shortSide)) * this.state.height} size={`${this.state.height}" by ${Math.floor(this.state.value * (this.props.longSide/this.props.shortSide))}"`}/>
                        }
                    </FadeIn>
                </div>
                </FadeIn>
            </div>
        )
    }
}