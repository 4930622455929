import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Navagation from './components/Navagation'
import MainSite from './pages/MainSite'
import LandingPage from './pages/LandingPage'
import OrderConfirmation from './pages/OrderConfirmation'

function App(props) {
  return (
    <div className="App">
      <Router>
        <Navagation />
        <Switch>
          <Route exact path='/'>
            <LandingPage />
          </Route>
          <Route path='/upload'>
            <MainSite />
          </Route>
          <Route path='/about'>
            <h1>About Grackel Canvas</h1>
          </Route>
          <Route path='/orderconfirmation'>
            <OrderConfirmation />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
